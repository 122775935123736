<template>
  <div class='template'>    
      <transition name="fade" appear>
      <article class="images">
          <div v-if="collage.length > 0" :style="{backgroundImage:`url(${collage[0].file})`}" />
      </article>    
      </transition>
      <article class='text-content'>          
        <transition name="slide" appear>
            <div>
                <div>
                    <ImageTitle transparent :title="page.title"/> 
                    <img :src="page?.background" alt="" class='fixed-image'>    
                    <AttachmentUI :page="page" :gallery="gallery" @showGallery="galleryVisible = true" @showVideo="showVideo"/>
                    <Editor @click="activateEditor" plugins="lists" api-key="2uyqbbnykquw7zpsd3ht43lzwe4s4dyu3l8x6p7vgvx8v4ug" :init="{menubar:false}" toolbar='[ undo redo | bold italic underline | fontsize forecolor | bullist numlist link ]' v-model="copy" class="copy" v-if="copy || $store.state.devMode" inline :disabled="!editorFocused" @change="updateContent"/>                        
                </div>                   
            </div>
        </transition>      
      </article>      
      <div class="linear-holder">
        <LinearButton :to="page?.previous" previous/>
        <LinearButton :to="page?.next"/>
      </div>
      <transition name="fade">
        <Gallery :images="gallery" @close="galleryVisible = false" v-show="galleryVisible"/>
      </transition>
      <transition name="fade">
        <Gallery videoMode :images="[]" :videoId="videoId" @close="videoVisible = false" v-if="videoVisible" :show="videoVisible"/>
      </transition>
  </div>  
</template>

<script>
import Page from '@/mixins/Page';
import AttachmentUI from '@/components/AttachmentUI';
import LinearButton from '@/components/LinearButton';
import ImageTitle from '@/components/ImageTitle';
import Gallery from '@/components/Gallery';
export default {
    name:'Small Fixed Image',
    mixins: [Page],
    props: {
        page:Object
    },
    components:{
        AttachmentUI,
        Gallery,
        LinearButton,
        ImageTitle
    },
    data() {
        return {
            galleryVisible:false,
            videoVisible:false,
            imagesFromGallery:1,
            copy:this.page.copy            
        }
    },
    computed: {      
        collage() {
            let images = [];            
            for (let index = 0; index < Math.min(this.imagesFromGallery, this.page.gallery?.length); index++) {
                const element = this.page.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        },  
        gallery() {
            let images = [];        
            for (let index = this.imagesFromGallery; index < this.page.gallery?.length; index++) {
                const element = this.page?.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        }
    }
}
</script>

<style lang="scss" scoped>
    @media (max-width:699px) {  
        .linear-holder {
            position: relative;
            height:90px;
        }
    }
    .template {
        display:grid;
        position: relative;         
        justify-content: flex-end;           
        background:url('~@/assets/images/bg_texture.jpg');
        background-size: cover;
        gap:35px;        
        box-sizing: border-box;           
        @media (min-width:700px) {
            padding:35px;    
            padding-left:0;
            padding-top:70px;
            grid-template-columns: minmax(50%, 930px)  minmax(275px, min-content);    
            padding-right: 260px;
        }   
        @media print {
            padding-top:155px;
            padding-right:35px;
            grid-template-columns: minmax(50%, 930px)  minmax(275px, min-content);    
        } 
         
    }        
    .text-content {
        -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
        color-adjust: exact;     
        box-sizing: border-box;
        color: #F5F5F5;       
        height:100%;
        display: inline-block;
        position: relative;    
        box-sizing: border-box;    
        @media (min-width:900px) {         
            text-align: right;
        }
        .copy {
            margin-top:18px;
            margin-bottom: 40px;
        }            
        
        @media print {
            > div {
                grid-template-columns: max-content 1fr;
                .copy {
                    padding-right:0;
                }
                div:not(.copy) {
                    margin-left:0;
                }
            }
        }                    
    }   
    .images {        
        display:grid;
        gap:1px;               
        height:100%;  
        min-height:35vh;
        position: relative;              
        > div {
            background-size: cover;
            background-position: center;
            -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
            color-adjust: exact;     
            overflow: hidden;
            display: flex;
            flex-direction: column;    
            position: relative;        
        }       
        .title {
            text-align: right;
            position: absolute;
            bottom: 0;
            right:0;
        } 
        
            
        }          
    .fixed-image {
        display: block;
        width:100%;        
    }
    .text-content .title { 
        text-align: left;
        padding:35px 0;
    }
    @media (min-width:900px) {
        .fixed-image {
            width:275px;
        }
        .text-content .title {       
            text-transform: uppercase;
            text-align: right;
            padding:0;
            padding-bottom:20px;
            position: absolute;
            right:0;
            top:0;
            transform: translateY(-100%);
        }  
    }
</style>